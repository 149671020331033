import { SVGAttributes, cloneElement } from "react";
import { useGetCountryFlag } from "hooks/DNB/useGetCountryFlag";
import { useGetIcon, useIcon } from "hooks/DNB/useGetIcon";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";

export const SectorIcon = ({ value }: { value: string }) => {
  const icon = useGetIcon(value);
  return icon;
};

export const CountryIcon = ({ value }: { value: string }) => {
  const icon = useGetCountryFlag(value);
  return icon;
};

interface IconProps extends SVGAttributes<SVGElement> {
  value: string;
  loading?: boolean;
}
export const Icon = ({ value, loading, ...restProps }: IconProps) => {
  const icon = useIcon(value);
  if (loading)
    return (
      <LoadingIndicator {...restProps} size="xs" />
    );
  return cloneElement(icon, { ...restProps });
};
