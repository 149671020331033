import { CircleFlag } from "react-circle-flags";

export const useGetCountryFlag = (code: string, width = 20) => {
  code = code.toLowerCase();
  switch (code) {
    case "dnk":
      code = "dk";
      break;
    case "swe":
      code = "se";
      break;
    case "kor":
      code = "kr";
      break;
    default:
      code = code;
  }
  return <CircleFlag countryCode={code.slice(0, 2)} width={width} />;
};
